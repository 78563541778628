<template>
  <div style="background: #F6F6F6; height: 100%;">
    <van-nav-bar
        title="申请退款"
        left-arrow
        fixed
        placeholder
        :border="false"
        @click-left="$router.go(-1)"
    />
    <div class="reason">
      <div class="reason-content">
        <div>
          <span style="color: #333;">退款原因</span>
          <span style="color: #FB2B53;">*</span>
        </div>
        
        <div class="reason-content-select" @click="showPopup">
          <span style="margin-right: 4px;">{{ selectReason }}</span>
          <van-icon color="#999" name="arrow"></van-icon>
        </div>
      </div>
    </div>
    <div class="describe">
      <p style="color: #333;">退款金额<span style="color: #FB2B53;">*</span></p>
      <p style="color: #ff6280;">￥{{ refundAmount }}</p>
      <p style="color: #999;">不可更改，最多￥{{ refundAmount }}，含运费￥{{ freightPrice }}</p>
    </div>

    <van-popup
      v-model="show"
      position="bottom"
      round
    >
      <div>
        <p style="text-align: center; color: #333; font-size: 14px; padding: 17px 0;">请选择退款原因</p>
        <div>
          <p
              class="clickReason"
              style="padding: 8px 0; font-size: 14px; color: #999; display: flex; align-items: center;"

              v-for="(item, index) in reason"
              :key="index"
              @click="clickReason(index, item)"
          >
            <van-icon style="margin: 0 8px 0 15px;" size="15" v-if="reasonIndex !== index" name="checked" />
            <van-icon style="margin: 0 8px 0 15px;" size="15" v-if="reasonIndex === index" name="checked" color="#FB2B53" />
            <span>{{ item }}</span>
          </p>
        </div>

        <div style="padding: 12px 15px;">
          <van-button @click="reasonConfirm" round size="large" color="#FB2B53">确认</van-button>
        </div>
      </div>
    </van-popup>

    <div class="voucher">
      <p>补充描述和凭证</p>
      <div class="voucher-message">
        <van-cell-group inset>
          <van-field
            v-model="description"
            autosize
            rows="1"
            type="textarea"
            maxlength="200"
            placeholder="补充描述，便于商家更好地处理售后问题"
            show-word-limit
          />
        </van-cell-group>

        <div class="voucher-message-camera">
          <div style="margin-left: 15px">
            <van-uploader
              preview-size="55"
              :after-read="afterRead(fileList)"
              v-model="fileList"
              :before-delete="deletePics"
              max-count="6"
            >
              <div class="Ge-icon">
                <i class="iconfont icon-xiangji"></i>
                <div class="Ge-icon-top">上传凭证</div>
                <div class="Ge-icon-bottom">最多六张</div>
              </div>
            </van-uploader>
          </div>
        </div>
      </div>

      <div style="padding: 20px 0">
        <van-button :disabled=disabled @click="submit" type="large" round color="#FB2B53">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { upload, orderrefunds } from '@/api/tsMallorderDetail'
import { Toast } from 'vant';
export default {
  name: 'refund',
  data() {
    return {
      show: false,
      reason: [
        '不想要了', '商品信息拍错了 (规格/尺寸/颜色等)',
        '地址/电话信息填写错误', '拍错了', '协商一致',
        '缺货', '其它'
      ],
      reasonIndex: -1,
      description: '',
      orderType: '',
      refundAmount: '',
      refundReson: '',
      selectReason: '请选择',
      freightPrice: '',
      orderId: '',
      fileList: [],
      pictures: []
    }
  },

  computed: {
    disabled () {
      let Boolean = true
      if (this.description.trim() != '' && this.refundReson != '') {
        Boolean = false
      } else {
        Boolean = true
      }
      return Boolean
    }
  },

  created () {
    if (this.$route.query.session) {
      this.$store.commit('setSession',  this.$route.query.session)
    }
    this.orderType = this.$route.query.data.orderType
    this.refundAmount = this.$route.query.data.refundAmount
    this.freightPrice = this.$route.query.data.freightPrice
    this.orderId = this.$route.query.data.orderId
  },

  methods: {
    showPopup() {
      this.show = true
    },

    clickReason(index, item) {
      this.reasonIndex = index
      this.refundReson = item
    },

    reasonConfirm () {
      this.show = false
      this.selectReason = this.refundReson
    },

    afterRead () {
      return (file) => {
        file.status = 'uploading'
        file.message = '上传中'
        let formData = new window.FormData()
        formData.append('file', file.file)
        formData.append('fileType', 'imgage/png'),
        formData.append('dir', 'material')
        upload(formData).then(res => {
          if (res.data.code === 0) {
            file.status = ''
            file.message = ''
            this.pictures.push(res.data.data)
          }
        }).catch(() => {
          file.status = 'failed',
          file.message = '上传失败'
        })
      }
    },

    deletePics (file, detail) {
      return new Promise((resolve, reject) => {
        this.$dialog.confirm({
          title: '截图',
          message: '确认删除截图？'
        }).then(() => {
          this.pictures.splice(detail.index, 1)
          this.$toast.success('删除成功')
          resolve()
        }).catch(() => {
          this.$toast.fail('已取消')
          reject()
        })
      })
    },

    submit () {
      const data = {
        orderId: this.orderId,
        orderType: this.orderType,
        refundReson: this.refundReson,
        refundAmount: this.refundAmount,
        description: this.description.trim(),
        pictures: this.pictures.join(','),
        status: '1'
      }
      orderrefunds(data).then(res => {
        if (res.data.code === 0) {
          // 可以添加一个进度条
          Toast.success('提交成功')
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.van-button--large {
  height: 40px;
}
/deep/ .van-nav-bar__left {
  padding: 0 5px;
}
.reason {
  background: white;
  border-top: 2px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  &-content {
    color: #333;
    font-size: 14px;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-select {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        text-align: end;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.describe {
  background: white;
  padding: 10px 15px;
  font-size: 14px;
}
.clickReason {
  border-bottom: 1px solid #E5E5E5;
}

.clickReason:active {
  background: #F6F6F6;
}
.voucher {
  padding: 0 15px;
  margin-top: 15px;
  background: white;
  p {
    font-size: 14px;
    color: #333;
    padding: 15px 0 9px;
  }
  &-message {
    background: #F7F7F7;
    padding: 10px 0;
    /deep/ .van-cell {
      padding: 0;
      .van-field__value {
        background: #F7F7F7;
      }
    }
  }
}
.Ge-icon {
  width: 56px;
  height: 56px;
  text-align: center;
  border: 1px dashed #999999;
  color: #999;
  div {
    font-size: 12px;
  }
  i {
    font-size: 20px;
  }
  &-top {
    transform: scale(.833) translateY(-5px);
  }
  &-bottom {
    transform: scale(.833) translateY(-11px);
  }
}
</style>